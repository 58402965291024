/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import Obj from "../utils/Obj"
import labels from "../../data/labels.json"

class I18n extends React.Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.defaultLabels = labels.en;
    this.currentLabels = this.defaultLabels;

    this.requireLabels();
  }

  requireLabels = () => {
    this.currentLabels = Obj.hasProp(labels, this.lang) ? labels[this.lang] : labels['en'];
  };

  text = (key, prms) => {
    let text = '';

    if (Obj.hasProp(this.currentLabels, key)) {
      text = Obj.hasProp(this.currentLabels[key], 'label') ? this.currentLabels[key].label : this.currentLabels[key];

    } else if (Obj.hasProp(this.defaultLabels, key)) {
      text = Obj.hasProp(this.defaultLabels[key], 'label') ? this.defaultLabels[key].label : this.defaultLabels[key].label;
    }



    let params = [];

    if (prms) {
      if (Array.isArray(prms)) {
        params = prms;
      } else {
        // if (prms === 0) {
        //   prms = '0';
        // }
        params.push(prms == 0 ? '0' : prms);
      }
    }

    params.forEach((param, index) => {
      text = text.replace(`{${index}}`, String(param));
    });

    text = text.replace(/\{\d+\}/, '');
    text = this.ReplaceCustomTags(text);

    return text;
  };

  ReplaceCustomTags = (text) => {
    let result = text;
    result = result.replace(/<f=tb>/g, '<span class="Ff(trailheadBold)">');
    result = result.replace(/<f=nft>/g, '<span class="Ff(neutraFaceTitling)">');
    result = result.replace(/<\/f>/g, '</span>');
    return result;
  };
}

I18n.propTypes = {
  lang: PropTypes.string.isRequired
};

export default I18n;
